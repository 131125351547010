import { render, staticRenderFns } from "./OrderBeautyEdit.vue?vue&type=template&id=01cb26fd&scoped=true"
import script from "./OrderBeautyEdit.vue?vue&type=script&lang=js"
export * from "./OrderBeautyEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01cb26fd",
  null
  
)

export default component.exports